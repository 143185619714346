import { PostAfterGoods } from "@/api/order/order.js";
import { GetGoodsInfo } from "@/api/goods/goods.js";

export default {
  components: {},
  data() {
    return {
      //uploadUrl: "http://172.21.25.32:8090/w1/order/images",
      uploadUrl: "",
      radio: "1",
      num: "",
      //准备提交的参数
      submitAfterGoods: {
        ofType: "0",
        goodsCode: "",
        goodsName: "",
        goodsNum: 1,
        reason: "",
        // price: 1,
        afterImg: [],
        goodsList: [],
      },
      afterImg: [],//申请面单 //订货网站前端售后申请只需要上传一次申请单图
      afterGoodsList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      lang: "en",
      showTips: false,
    };
  },
  created() {
    this.setUploadUrl();
    this.lang=window.sessionStorage.getItem("lg") || "en";
  },
  methods: {
    setUploadUrl() {
      this.uploadUrl=this.$root.DownUrl + "/w1/order/images";
    },
    //商品上传文件
    GuploadSuccess(response, file, fileList) {
      this.submitAfterGoods.goodsList=fileList;
    },
    GhandleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    //==========================
    //删除一条商品列表
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    //获取商品信息
    getGoodsBySku() {
      let lange=window.sessionStorage.getItem("lg");
      if (this.submitAfterGoods.goodsCode === "") return;
      GetGoodsInfo(this.submitAfterGoods.goodsCode).then((res) => {
        let goodsDataInfo=res.data.data;
        if (goodsDataInfo.id < 1) {
          if (lange === "zh-CN") {
            this.$message.error("未找到商品信息");
          } else {
            this.$message.error("Goods not found!");
          }
          this.submitAfterGoods.goodsCode="";
        } else {
          this.submitAfterGoods.goodsCode=goodsDataInfo.item_no;
          this.submitAfterGoods.goodsNum=goodsDataInfo.purchase_spec;
          if (lange === "zh-CN") {
            this.submitAfterGoods.goodsName=goodsDataInfo.item_name;
          } else {
            this.submitAfterGoods.goodsName=goodsDataInfo.item_en;
          }
        }
      }).catch(() => {
        this.$message.error("未找到商品信息");
      });
    },
    //添加商品到文件列表
    addGoods2file() {

      let goodsFilelist=this.submitAfterGoods.goodsList;
      let goodsFileArr=[];
      for (let i=0; i < goodsFilelist.length; i++) {
        if (goodsFilelist[i].response.data) {
          goodsFileArr.push(goodsFilelist[i].response.data);
        }
      }
      if (goodsFilelist.length < 1) {
        //this.$message.success("请上传商品图片");
        this.$message.error(this.$t("Please upload product image"));
        return;
      }
      // if (this.submitAfterGoods.afterImg.length < 1) {
      //   //this.$message.success("请上传面单图片");
      //   this.$message.error(this.$t("Please upload the flyer according to the picture"));
      //   return;
      // }
      if (this.afterImg.length < 1) {
        //this.$message.success("请上传面单图片");
        this.$message.error(this.$t("Please upload the flyer according to the picture"));
        return;
      }

      if (this.submitAfterGoods.goodsCode === "") {
        //this.$message.success("请填写商品代码");
        this.$message.error(this.$t("Please fill in the t code"));
        return;
      }
      if (this.submitAfterGoods.number < 1) {
        //this.$message.success("请设置商品数量");
        this.$message.error(this.$t("Please set the product quantity"));
        return;
      }
      if (this.submitAfterGoods.reason === "") {
        //this.$message.success("请描述申请原因");
        this.$message.error(this.$t("Please describe the reason for the application"));
        return;
      }

      //申请面单
      let afterArr=[];
      // for (let i=0; i < this.submitAfterGoods.afterImg.length; i++) {
      //   afterArr.push(this.submitAfterGoods.afterImg[i].response.data);
      // }
      // 订货网站前端售后申请只需要上传一次申请单图
      for (let i=0; i < this.afterImg.length; i++) {
        afterArr.push(this.afterImg[i].response.data);
      }

      for (let i=0; i <= this.afterGoodsList.length; i++) {

        if (this.afterGoodsList[i] && this.afterGoodsList[i].name === this.submitAfterGoods.goodsName) {
          const msg=this.$lang === "zh-CN" ? "请不要添加相同产品" : "Don't add same goods";
          this.$message.error(msg);
          return;
        }

      }

      let theAfterList={
        "name": this.submitAfterGoods.goodsName,
        "sku": this.submitAfterGoods.goodsCode,
        "number": this.submitAfterGoods.goodsNum,
        "reason": this.submitAfterGoods.reason,
        "afterImg": afterArr,
        "goodsImg": goodsFileArr,
      };
      this.afterGoodsList.push(theAfterList);
      this.submitAfterGoods={
        ofType: "0",
        goodsCode: "",
        goodsName: "",
        goodsNum: 1,
        reason: "",
        afterImg: [],
        goodsList: [],
      };
      this.afterImg=[];
      this.submitAfterGoods.goodsList=[];
    },
    //提交售后
    submitApp() {
      //缺少商品列表不允许提交
      if (this.afterGoodsList.length <= 0) {
        this.$message.error("Click \"Add goods\" before click \"Submit\"");
        return;
      }
      let postData={
        "afterType": parseInt(this.submitAfterGoods.ofType),
        "goodsList": this.afterGoodsList,
      };

      PostAfterGoods(postData).then((res) => {
        this.$message.success("submit successfully");
        this.$router.push("/web/orderCenter/afterSalesRecord");
      }).catch((err) => {
        const data=err.response.data;
        this.$message.error("goods item : " + data.message + " not found");
      });
    },
    //上传成功后的回调
    uploadSuccess(response, file, fileList) {
      // this.submitAfterGoods.afterImg=fileList;
      this.afterImg=fileList;//订货网站前端售后申请只需要上传一次申请单图
    },
    //删除
    handleRemove(file, fileList) {

    },
    //点击放大
    handlePictureCardPreview(file) {
      this.dialogImageUrl=file.url;
      this.dialogVisible=true;
    },
  },
};
