<template>
  <div class="rightSide">
    <div class="rightSide_inner">
      <div class="rightSide_inner_title">
          <span style="font-size: 30px; color: #333333">
            {{ $t("Apply for After-Sales Service") }}
          </span>
        <el-tooltip placement="bottom" effect="light">
          <div slot="content" style="text-align:center;width:700px">
            <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/aftersell/aftersell-cn.png')" alt="" style="height:100%;width:100%">
            <img v-if="lang === 'en'" :src="require('@/assets/images/aftersell/aftersell-en.png')" alt="" style="height:100%;width:100%">
          </div>
          <i class="el-icon-question" style="font-size: 26px;margin-left:10px"></i>
        </el-tooltip>
        <el-tooltip placement="bottom" effect="light">
          <div slot="content" style="text-align:center;width:900px">
            <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/aftersell/aftersell-submit-tips-cn.png')" alt="" style="height:100%;width:100%">
            <img v-if="lang === 'en'" :src="require('@/assets/images/aftersell/aftersell-submit-tips-en.png')" alt="" style="height:100%;width:100%">
          </div>
          <i class="el-icon-question" style="font-size: 26px;margin-left:10px"></i>
        </el-tooltip>
      </div>

      <p style="font-size: 20px; color: #333333; margin-top: 10px">{{ $t("Fill in after-sales information") }}.</p>
      <div style="margin-top: 20px">
        <el-radio v-model="submitAfterGoods.ofType" label="0">
          {{ $t("Quality Problems") }}
        </el-radio>
        <el-radio v-model="submitAfterGoods.ofType" label="1">{{ $t("Shipping Damage") }}</el-radio>
      </div>

      <div class="input">
        <div class="input_info">
          <p class="input_text">{{ $t("Product Barcode") }}：</p>
          <el-input v-model="submitAfterGoods.goodsCode" :placeholder="$t('Please enter the product number')"
                    size="middle" class="input_select" clearable @change="getGoodsBySku" />
        </div>

        <div class="input_info">
          <p class="input_text">{{ $t("Product Name") }}：</p>
          <el-input v-model="submitAfterGoods.goodsName" :placeholder="$t('Please enter the product name')"
                    size="middle" class="input_select" clearable />
        </div>

        <div class="input_info">
          <p class="input_text">{{ $t("Qty") }}：</p>

          <el-input-number v-model="submitAfterGoods.goodsNum" size="middle" class="input_select"
                           controls-position="right" :min="1" :max="999">
          </el-input-number>
        </div>

        <div class="input_info">
          <p class="input_text">{{ $t("Reason") }}：</p>
          <el-input v-model="submitAfterGoods.reason" :placeholder="$t('Please enter after-sales reason')"
                    type="textarea" size="middle" class="input_select" clearable />
        </div>

        <div class="input_info" style="width: 100%">
          <p class="input_text">{{ $t("Courier Receipt") }}：</p>
          <div style="border: #218da0 1px solid">
            <el-upload :action="uploadUrl" list-type="picture-card" size="mini"
                       :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                       :on-success="uploadSuccess">
              <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
            </el-upload>
<!--            图片放大预览-->
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
          <div style="margin: 10px">
            <span v-if="lang === 'zh-CN'">注意：需提供港口提单或其他能证明货物签收日期的文件，如未能提供，系统配货打包时间将被视为货物签收时间；</span>
            <span v-if="lang === 'en'">Notice: Need to provide the B/L or other documents that can prove the date of receipt of the goods, if not, the system packing date will be regarded as the time of receipt of the goods.</span>
          </div>
        </div>

        <div class="input_info" style="width: 100%">
          <p class="input_text">{{ $t("product picture") }}：</p>
          <div style="border: #218da0 1px solid">
            <el-upload class="upload-demo" :action="uploadUrl" multiple :limit="3" list-type="picture-card"
                       :on-success="GuploadSuccess" :on-remove="handleRemove"
                       :on-exceed="GhandleExceed" :on-preview="handlePictureCardPreview">
              <!--            <el-button size="small" type="primary">{{ $t("click upload") }}</el-button>-->
              <i class="el-icon-plus" style="width: 50px; height: 50px;"></i>
            </el-upload>
          </div>
          <div style="margin: 10px">
            <span v-if="lang === 'zh-CN'">注意：上传的图片和录像需展示所申报数量（如：申报2个，图片和视频中需展示2个破损产品）</span>
            <span v-if="lang === 'en'">Notice: The uploaded pictures and videos need to show the damaged quantity (e.g.: damaged 2pcs, the pictures and videos need to show 2 broken products clearly)</span>

          </div>
        </div>

        <div class="input_info">
          <p class="input_text"></p>
          <div slot="tip" class="el-upload__tip">
            {{ $t("upload files images") }}
          </div>
        </div>

      </div>

      <div class="input" style="margin-left: 130px">
        <el-button type="danger" size="medium" @click="addGoods2file">{{ $t("add goods") }}</el-button>
        <el-button type="primary" size="medium" style="background: #238da5" @click="showTips = true" :disabled="afterGoodsList.length <= 0">
          {{ $t("Submit") }}
        </el-button>
        <div style="padding-left: 10px" v-if="lang === 'zh-CN'">先点击“添加产品”，再点击“提交”</div>
        <div style="padding-left: 10px" v-if="lang === 'en'">Click "Add goods" before click "Submit"</div>
      </div>

      <!--  添加商品的列表  -->
      <div style="border:1px solid #238da5; margin-top: 10px;">
        <el-table :data="afterGoodsList" height="300" border style="width: 100%">
          <el-table-column prop="name" :label="$t('Name')" width="230" align="center">
          </el-table-column>
          <el-table-column prop="sku" :label="$t('Product Barcode')" width="180" align="center">
          </el-table-column>
          <el-table-column prop="number" :label="$t('Quantity')" width="100" align="center">
          </el-table-column>
          <el-table-column prop="reason" :label="$t('reason for')" align="center">
          </el-table-column>
          <el-table-column :label="$t('Courier Receipt')" align="center">
            <template v-slot="scope">
              <img :src="item" height="50" v-for="(item,index) in scope.row.afterImg" alt="">
            </template>
          </el-table-column>
          <el-table-column :label="$t('product picture')" align="center">
            <template v-slot="scope">
              <img :src="item" height="50" v-for="(item,index) in scope.row.goodsImg" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="sku" :label="$t('Operation')" align="center" width="100">
            <template v-slot="scope">
              <el-button @click.native.prevent="deleteRow(scope.$index, afterGoodsList)" type="text"
                         size="small">
                {{ $t("Delete") }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-bottom: 30px;height: 30px;">&nbsp;</div>
    </div>

    <el-dialog width="45%" :visible.sync="showTips" :close-on-click-modal="false">
      <div class="pd20">
        <img v-if="lang === 'zh-CN'" :src="require('@/assets/images/aftersell/aftersell-submit-tips-cn.png')" alt="" style="height:100%;width:100%">
        <img v-if="lang === 'en'" :src="require('@/assets/images/aftersell/aftersell-submit-tips-en.png')" alt="" style="height:100%;width:100%">
      </div>
      <div class="pd20 h80">
        <el-button @click="submitApp">{{ $t("Submit") }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import applyAfterSalesList from "./js/index";

export default applyAfterSalesList
</script>
<style lang="scss" scoped>
.rightSide {
  width: 1320px;
  //overflow-y: scroll;
  margin: 15px 0 0 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  &_inner {
    width: 1218px;
    &_title {
      width: 1218px;
      height: 68px;
      margin-top: 30px;
    }
  }
}

.table_style {
  width: 1218px;
  height: 68px;
  margin-top: 30px;
}

.footer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;

  &_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -70px;
    margin-top: 10px;
    width: 500px;
  }

  &_text {
    width: 200px;
    flex-shrink: 0;
    text-align: right;
  }

  &_select {
    width: 270px;
    flex-shrink: 0;
  }
}
</style>
<style scoped>
.el-radio /deep/ .el-radio__label {
  font-size: 18px;
}
</style>
